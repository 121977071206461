<div class="main-container">
  <div class="card">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Aktuelles</a></p>
    </div>
    <h1 class="nav-bar-link">Instrumente</h1>
    <p>
      Hier findest du einen Überblick der Instrumente, die wir bei unseren
      Klangerlebnissen verwenden. Solltest du Fragen haben, kannst du dich
      natürlich gerne jederzeit bei uns melden.
    </p>
  </div>
  <div class="card">
    <div class="">
      <div class="flex-1">
        <h1>Klangschalen – "heilsame Klänge"</h1>
        <p>
          Klangschalen bestehen traditionell aus einer Legierung aus Kupfer und
          Zinn (Bronze) und können darüber hinaus eine Reihe von Begleitmetallen
          enthalten (z. B. Eisen, Zink, Blei, Arsen und Antimon sowie Spuren von
          Silber, Gold, Selen und Tellur). In alten Klangschalen wurden weniger
          gründlich raffinierte Metalle eingesetzt, so dass hier mehr
          Begleitmetalle vorkommen.
        </p>
        <p>
          Bei der Herstellung wird das Metallgemisch geschmolzen und in kleinen
          Mengen in Gussformen gefüllt. Aus dem flachen, runden Metall-Rohling
          wird die Klangschale unter Hitze in Form getrieben und anschließend
          poliert.
        </p>
        <p>
          Die handwerkliche Fertigung der Schalen wurde in der Himalaya-Region
          zwischen 1900 und 1940 fast völlig aufgegeben und erst in jüngerer
          Zeit auf Grund der westlichen Nachfrage wieder aufgenommen.
        </p>
        <p>
          Der Durchmesser einer Klangschale variiert zwischen zehn und über
          fünfzig Zentimeter, die Wanddicke zwischen etwa 0,5 und 5 Millimeter.
          Klangschalen sind ursprünglich ein traditionelles Küchengeschirr aus
          dem fernöstlichen Raum, vornehmlich aus Tibet, Nepal, China, Japan und
          Indien.
        </p>
        <p>
          Dem Klang der Schalen werden in der westlichen Welt präventive und
          heilende Wirkungen zugeschrieben, für die es jedoch keine
          wissenschaftlichen Belege gibt. Für eine Therapie mit Klangschalen
          werden diese auf den Körper aufgesetzt und mit einem Klöppel
          angeschlagen oder nahe am Körper gespielt.
        </p>
      </div>
      <div class="flex-1">
        <img
          class="banner klangschalen"
          src="assets/img/Klangschalen.png"
          alt="Klangschalen"
        />
      </div>
    </div>
  </div>
  <div class="card">
    <div class="splitted">
      <div class="flex-1">
        <img class="banner" src="assets/img/Gong.jpg" alt="Gong" />
      </div>
      <div class="flex-1">
        <h1>Gong "berührt"</h1>
        <p>
          Die Herkunft von Gongs verliert sich im Dunkel der Geschichte. Die
          früheste schriftliche Quelle, dass Gongs in China verwendet wurden,
          stammt aus der Zeit 500-516 n.Chr.
        </p>
        <p>
          Gongs gibt es in vielfältigen Formen. Sie können scheiben - und
          schüsselförmig, flach und gewölbt, mit schmalem oder breitem Rand, mit
          und ohne Buckel sein. Diese vielfältigen Formen haben eine große
          Bandbreite an Klängen und Obertönen. Es gibt Instrumente mit
          Durchmessern von ca. 12 cm bis hin zu einer Größe von 1,4 Metern,
          mitunter noch größer.
        </p>
        <p>
          Der Gong wirkt durch seinen Klang und hat zusätzlich auch eine
          visuelle Wirkung. Gongs schaffen durch ihr Aussehen eine besondere
          Atmosphäre im Raum.
        </p>
        <p>
          Klänge des Gongs sprechen Erfahrungsräume in uns an, die oft jenseits
          der "begrifflichen" Welt liegen, das bedeutet der Gong kann eine
          bewusstseinserweiternde Funktion haben, kann befreiend und bereichernd
          sein.
        </p>
      </div>
    </div>
  </div>
  <div class="card">
    <h1>Didgeridoo "Erdung"</h1>
    <img class="didgeridoo" src="assets/img/Didgeridoo.jpg" alt="Didgeridoo" />
    <p>
      Es wird angenommen, dass das Didgeridoo seinen Ursprung im Norden
      Australiens, im sogenannten Arnhemland, hatte. Hier wurde das
      Blasinstrument sowohl zum gemeinschaftlichen Musizieren als auch für
      zeremonielle Zwecke genutzt.
    </p>
    <p>
      Die ersten, derzeit bekannten Hinweise, für das Vorkommen des Didgeridoo,
      sind ungefähr 2500–3500 Jahre alte Felsmalereien. In der
      Aborigine-Mythologie gilt das Instrument als sehr viel älter, über 40.000
      Jahre alt.
    </p>
    <p>
      Das Didgeridoo gehört zur Familie der Aerophone und ist ein obertonreiches
      Blas- und Rhythmusinstrument. Es wird meist aus einem 1 - 2,50 Meter
      langen Eukalyptusstamm angefertigt, der von Termiten ausgehöhlt wird.
    </p>
    <p>
      Das Mundstück besteht häufig aus einem Wachsring zum Schutz der Lippen,
      der bei günstig gewachsenen oder gut verarbeiteten Instrumenten aus
      Europa, auch fehlen kann.
    </p>
    <p>
      Das Didgeridoo wird mit "flatternden" Lippen sanft angeblasen. Dadurch wir
      die Luftsäule innerhalb des Didgeridoos zum Schwingen gebracht, es
      entstehen ein Grundton und viele Obertöne. Durch die, physikalisch
      gesehen, Interferenzen dieser drei Klangquellen – schwingende Luftsäule,
      Grundton, Obertöne – entsteht ein ganzes Spektrum von Schwebungen und
      Tönen. Zusätzlich besteht die Möglichkeit, durch die Technik der
      Zirkularatmung (durchgehender permanenter Ton) die unterschiedlichen
      "Klangerzeuger" wie Sprache, Stimme, Verschlusslaute, Zunge, Kiefer,
      Kehlkopf, Wange nahtlos aneinanderzufügen.
    </p>
  </div>
  <div class="card">
    <div class="splitted">
      <div></div>
      <div>
        <h1>Handpan "verzaubert"</h1>
        <img class="handpan" src="assets/img/Handpan.jpg" alt="Handpan" />
        <p>
          Handpan ist der Name für eine Gruppe von Musikinstrumenten, die als
          Reaktion auf das weltweit große Interesse für das Hang entstanden ist,
          einem handgespielten Blechklanginstrument, das die Schweizer PANArt
          Hangbau AG, Bern, im Jahr 2000 entwickelte und bis 2013 baute.
        </p>
        <p>
          Die meisten Handpan übernehmen vom Hang die Grundform, bestehend aus
          zwei miteinander verklebten Halbkugelsegmenten, einem zentralen
          Tonfeld und einem Ring aus mindestens sieben Tonfeldern auf der
          Oberseite und einer Öffnung auf der Unterseite.
        </p>
        <p>
          Diese Handpans unterscheiden sich jedoch in etlichen Eigenschaften
          nicht nur von dem ursprünglichen Hang, sondern auch untereinander. Für
          die Rohformen kommen unterschiedliche Stahlblechsorten, Blechstärken
          und Herstellungsverfahren zum Einsatz. Zu unterscheiden sind Handpans
          von der Steel Tongue.
        </p>
        <p>
          Diese sind ähnliche Instrument wie eine Handpan, unterscheiden sich
          dennoch merklich von ihnen. Bei einer Steel Tongue Drum werden die
          Klangfelder in das Instrument eingeschnitten oder gelasert, während
          bei einer Handpan das Klangfeld mit dem Hammer geschlagen wird.
        </p>
      </div>
    </div>
  </div>
</div>
