<div class="header">
  <img
    class="banner hidden-on-mobile"
    src="assets/banner/desktop.jpg"
    alt="Ute mit Klangschale und Ewald mit Didgeridoo"
  />
  <img
    class="banner hidden-on-desktop"
    src="assets/banner/mobile.jpg"
    alt="Ute mit Klangschale und Ewald mit Didgeridoo"
  />
</div>
<div class="nav-bar">
  <div class="nav-padding">
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/home'"
        routerLink="/home"
        routerLinkActive="active"
        >Aktuelles</a
      >
    </div>
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/angebote'"
        routerLink="/angebote"
        routerLinkActive="active"
        >Angebote</a
      >
    </div>
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/ueber-uns'"
        routerLink="/ueber-uns"
        routerLinkActive="active"
        >Wir über uns</a
      >
    </div>
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/instrumente'"
        routerLink="/instrumente"
        routerLinkActive="active"
        >Instrumente</a
      >
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<div class="footer">
  <div class="footer-padding">
    <p>
      <a
        [class.bold-and-underline]="activeURL === '/datenschutz'"
        routerLink="/datenschutz"
        routerLinkActive="active"
        >Datenschutz</a
      >
    </p>
    <p>
      <a
        [class.bold-and-underline]="activeURL === '/impressum'"
        routerLink="/impressum"
        routerLinkActive="active"
        >Impressum</a
      >
    </p>
  </div>
</div>
