import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CsvParserService {
  constructor(private http: HttpClient) {}

  //var csv is the CSV file with headers
  private csvJSON(csv) {
    var lines = csv.split('\n');

    var result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split('\t');

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split('\t');

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return result; //JSON
  }
  public getData(): Observable<any> {
    //const url = `https://docs.google.com/spreadsheets/d/e/2PACX-1vRRlKr5ihoIcbSrhlBvHNOV6MoyXpeUr_DwuuYUzJCUy1YoPaZA3b3Jdc2xw1MPxp3VxEknyW0QKHvl/pub?output=text`;
    const url = `https://docs.google.com/spreadsheets/d/10-tVzyL7jXXmKW5_-Z4IwlKispTYlPVYFBy-C4X4L38/export?format=tsv&id=10-tVzyL7jXXmKW5_-Z4IwlKispTYlPVYFBy-C4X4L38&gid=0`;
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((res: any) => {
        return this.csvJSON(res);
      })
    );
  }
}
