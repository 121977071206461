<div class="main-container">
  <div class="card">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Aktuelles</a></p>
    </div>
    <h1>Wir über uns</h1>
    <div class="splitted">
      <div class="flex-2">
        <div class="quotation text-center">
          <img src="assets/img/quote_start.svg" alt="Zitat_Beginn" />
          <p class="margin-bottom-0">
            <i
              >"Das Spielen der Klangschalen ist für mich immer eine spirituelle
              Reise - eine Reise in mein wirkliches SELBST, meine Weltseele."</i
            >
          </p>
          <img src="assets/img/quote_end.svg" alt="Zitat_Ende" />
          <h3 class="text-right">Namaste.</h3>
        </div>
        <p>
          Auf einer meiner vielen Reisen durch Indien, China und Nepal bin ich
          immer mal wieder mit Klangschalen in Berührung gekommen, bis ich dann
          2014 in Kathmandu eine Klangschalen Ausbildung begonnen habe.
          <a class="underline" href="http://www.kundalinisoundhealing.com/"
            >(Kundalini)</a
          >
        </p>
        <p>
          Durch meine eigenen positiven Erfahrungen auf dem Weg der Ausbildung,
          ist es mir gelungen, mehr Zufriedenheit und geistige Ruhe in meinen
          Alltag zu integrieren. Inzwischen sind Meditation, Entspannung und die
          Chakren Arbeit ein ausgleichender Teil meines Tages geworden.
        </p>
        <p>
          Der Klang der Klangschalen berührt mich und andere auf tiefster,
          körperlicher und seelischer Ebene und so fühlen sich die Personen nach
          meiner Klang Meditation entspannt und geistig wach.
        </p>
        <h3>Ute</h3>
      </div>

      <div class="flex-1">
        <img
          class="banner"
          src="assets/img/Ute_portrait.jpg"
          alt="Ute Bettenhausen"
        />
      </div>
    </div>
  </div>
  <div class="card">
    <div class="splitted">
      <div class="flex-1">
        <img
          class="banner"
          src="assets/img/Ewald_portrait.jpg"
          alt="Ewald Ring"
        />
      </div>
      <div class="flex-2">
        <div class="quotation text-center">
          <img src="assets/img/quote_start.svg" alt="Zitat_Beginn" />
          <p class="margin-bottom-0">
            <i
              >"Didgeridoo und Handpan sind für mich Zufriedenheit und das
              Ankommen im Augenblick"</i
            >
          </p>

          <img src="assets/img/quote_end.svg" alt="Zitat_Ende" />
        </div>
        <p>
          Viele Jahre des autodidaktischen Lernens und Unterricht bei namhaften
          Didgeridoo - Spielern haben bis heute meine Faszination für dieses
          außergewöhnliche Instrument immer mehr gesteigert. Der Eigenbau von
          einigen meiner Didgeridoo, aus heimischen Hölzern, hat eine tiefe
          Verbundenheit zu jedem der Instrumente hergestellt und bereichert bis
          heute die Klangvielfalt und Einsatzmöglichkeiten meiner Didgeridoo.
        </p>
        <p>
          Über Didgeridookurse an Interessierte konnte ich meine Begeisterung
          für das Didgeridoo und den Klang schon vielfach weitergeben.
        </p>
        <p>
          Seit meiner Ausbildung zum Rhythmustrainer im "Freien Musikzentrum
          München", begeistere ich mich für jegliche Art von Trommeln,
          Klanginstrumenten und ganz besonders für die Handpan.
        </p>
        <p>
          Handpan spielen fasziniert besonders, durch das gleichzeitige
          Zusammenspiel von Melodie und Rhythmus.
        </p>
        <p>Töne, Stille, Schwingung und Atmung sind für mich Lebendigkeit</p>
        <h3>Ewald</h3>
      </div>
    </div>
  </div>
</div>
