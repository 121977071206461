import { CsvParserService } from './../csv-parser-service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  public data;
  constructor(private csvParser: CsvParserService) {
    csvParser.getData().subscribe((res) => {
      this.data = res;
    });
  }

  ngOnInit(): void {}
}
