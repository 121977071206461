<div class="main-container">
  <div class="card imprint">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Aktuelles</a></p>
    </div>
    <h1>Datenschutz</h1>
    <p>
      Für unsere Webseite benötigen und sammeln wir keinerlei personenbezogene
      Informationen über unsere Nutzer. Jedoch nutzen wir Firebase als
      Serviceanbieter für das Hosting unserer Website.
    </p>
    <h3>Firebase Cloud Storage</h3>
    <p>
      Firebase Cloud Storage ist ein Webhosting Dienst, bereitgestellt von
      Google Inc. Erhobene personenbezogene Daten: Nutzungsdaten und
      verschiedene Datenarten, wie in der Datenschutzerklärung des Dienstes
      beschrieben. Wir nutzen nach Möglichkeit Server mit einem Standort
      innerhalb der EU. Es ist aber nicht auszuschließen, dass Daten auch in die
      USA übertragen werden.Nähere Informationen zu Google Firebase und
      Datenschutz finden Sie unter
      <a href="https://policies.google.com/privacy"
        >https://policies.google.com/privacy</a
      >
      sowie
      <a href="firebase.google.com">firebase.google.com</a>.
    </p>
  </div>
</div>
