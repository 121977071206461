import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  public activeURL: string = '/home';
  public subNavIsClosed: boolean = true;

  private popupOpenSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (window.scrollY > 300) {
          window.scroll(0, 0);
        }
        this.activeURL = val.url;
        this.subNavIsClosed = true;
      }
    });
  }
  ngOnInit() {}
  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
  }

  public toggleSubNav() {
    this.subNavIsClosed = !this.subNavIsClosed;
  }
}
