<div class="main-container">
  <div class="card">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Aktuelles</a></p>
    </div>
    <h1 class="nav-bar-link show-mobile">Angebote</h1>
    <p>
      Hier findest du einen Überblick unserer Angebote. Solltest du Fragen
      haben, kannst du dich natürlich gerne jederzeit bei uns melden.
    </p>
  </div>
  <div class="card">
    <h1>Klangreise – als Paar oder in der Gruppe</h1>
    <p>
      Verschiedenste archaische Instrumente schaffen obertonreiche Klänge,
      sanftes Massieren der Zellen, machen Schwingungen und Vibrationen spürbar.
      Melodien und Rhythmen beleben, tragen und ermöglichen Aktivierung.
    </p>
  </div>
  <div class="card">
    <h1>Klang Massagen – Einzelsitzung</h1>
    <p>
      Sitzung mit Klangschalen oder Monochord.<br />
      Diese werden gezielt auf bestimmte Körperstellen aufgesetzt und sanft
      angespielt.<br />
      Sitzung mit Didgeridoo auf der Klangliege. Der Resonanzraum der Liege
      verstärkt die Schwingungen des Didgeridoo um ein Vielfaches, wodurch die
      Vibrationen auf den gesamten Körper wirken. Eine intensive ganzkörperliche
      Klangerfahrung.
    </p>
  </div>
  <div class="card">
    <h1>"Chakra Healing" - Einzeltherapie mit Klangschalen</h1>
    <p>Individuell abgestimmte Heilung der Energiezentren im Körper</p>
  </div>
  <div class="card">
    <h1>Meditation</h1>
    <p>
      Stille genießen und eine Verbindung zu die Selbst herstellen.<br />
      Meditation hebt unsere Stimmung, verbessert unseren Umgang mit unseren
      Gefühlen, verstärkt unsere positiven Persönlichkeitseigenschaften, erhöht
      unsere Konzentrationsfähigkeit und macht unser Denken klarer.<br />
      Schon kurze, aber regelmäßige, Meditationseinheiten wirken sich positiv
      auf Körper, Geist und Seele aus.<br />
      Zum Abschluss einer jeden Meditationseinheit erhältst du ein kurzes,
      wohltuendes "Klangbad".
    </p>
  </div>
  <div class="card">
    <h1>Wohlfühlmassage mit Klanguntermalung - Die Heilkraft der Hände</h1>
    <p>
      Verschiedenartige Streichungen und sanfte Kreisungen sind eine Wohltat für
      Körper, Seele und Geist. Eine sanfte Massage, unterstützt mit Klang und
      Melodie, erfüllt das Bewusstsein mit Glück und Zufriedenheit.
    </p>
  </div>
  <div class="card">
    <h1>Klangschalen "Basis Workshop"</h1>
    <h3>Inhalte des Workshops mit mindestens 2 Klangschalen</h3>
    <p>Dauer 4 Stunden</p>
    <p>
      Die Historie der Klangschalen und des Klangs<br />
      Klang und Vibration – die Wirkung auf den menschlichen Körper<br />
      Einführung in die Chakren und deren Position im Körper<br />
      Die wesentlichen Abspieltechniken mit Drum Stick und Klöppel<br />
      Den Einsatz von Atem und Vokalvibration<br />
      Das 7/14 oder 21 Tage Programm zur Selbstanwendung mit einer
      Klangschale<br />
      ... und ganz nebenbei erlebst du die Kraft des Klangs, fühlst dich
      aufgetankt mit neuer Energie.
    </p>
  </div>
  <div class="card">
    <h1>Klangschalen "Ausbildungsstunde"</h1>
    <h3>Inhalt der Stunde mit einer Klangschale</h3>
    <p>Dauer 1,5 Stunden</p>
    <p>
      Du hast eine eigene Klangschalen und möchtest diese gerne für dich
      persönlich zur Anwendung bringen?<br />
      Gerne zeige ich dir die Haltung der Schale, die Anwendung von
      verschiedenen Klöppeln und die Klangfacetten deiner Schale mit all ihren
      Obertönen und ihrer Klangdauer.<br />
      Des Weiteren die Vermittlung von einfachen Anwendungsmöglichkeiten bei dir
      selbst.<br />
      Zum Abschluss erhältst du eine kurze Klanganwendung u.a. mit deiner
      Klangschale
    </p>
  </div>
  <div class="card">
    <h1>Didgeridoo Workshop</h1>
    <h3>Erlernen von:</h3>
    <ul>
      <li>Grundton</li>
      <li>Zirkularatmung</li>
      <li>Spieltechniken</li>
      <li>Stimmeinsatz</li>
      <li>Spielen von Rhythmen</li>
      <li>
        ... und ganz nebenbei stellt sich die richtige Atmung für deinen Alltag
        ein.
      </li>
    </ul>
  </div>
  <div class="card">
    <h1>Vortrag</h1>
    <h3>Klang und Heilung - Warum Klänge heilsam wirken</h3>
    <p>
      Von der Geschichte des Klangs über seine Wirkung bis hin zu
      Heilungsprozessen durch Klang.
    </p>
  </div>
</div>
